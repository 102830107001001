<template>
  <div class="row"><div class="col-12 col-lg-6 c"><div class="card card-body text-center"><img src="https://cdn-static.brmja.com/storage/scrapped/625613640a69d108629998.gif" alt="" class="c" style="width: 160px;"><h4>التحضير بواسطة اجهزة البصمة</h4><p> للتحكم في اجهزة البصمة، برجاء الذهاب لصفحة اجهزة البصمة في نظام التحضير الذكي <a href="javascript:;" @click='$router.push(`/devices`)'>من هنا</a><br> وبعدها قم باضافة جهاز جديد للمعلمين. </p></div></div></div>
</template>

<script>
export default {

}
</script>

<style>

</style>